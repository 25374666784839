import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormControlLabel,
  TextField,
  Typography,
} from "@mui/material";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/material.css";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../Redux/reducer/reducer";
import { styled } from "@mui/material/styles";

import Switch from "@mui/material/Switch";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";

export default function InformationsDeContact({
  formData,
  handleItemClick,
  setRef,
}) {
  const [selectedCountry, setSelectedCountry] = useState({
    name: "Maroc",
    dialCode: formData.phone_code || "+212",
    countryCode: "ma",
  });
  const [checked, setChecked] = useState(formData.phone_check); // Default is checked (true)

  const handlePhoneChange = (_, country) => {
    setSelectedCountry(country);
    handleItemClick("phone_code", `+${country.dialCode}`);
  };

  const userData = useSelector(selectUser);
  useEffect(() => {
    if (userData && !formData.phone_number) {
      handleItemClick("phone_number", userData.telephone);
    }
    if (!formData.phone_code) {
      handleItemClick("phone_code", selectedCountry.dialCode);
    }
  }, [userData]);

  const handlePhoneNumberChange = (event) => {
    const { name, value } = event.target;
    if (/^\+?\d*$/.test(value)) {
      if (value.length === 1 && value !== "+" && value.length <= 10) {
        handleItemClick("phone_code", selectedCountry.dialCode);
      }
      handleItemClick(name, value);
    }
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
    handleItemClick("phone_check", event.target.checked);
  };
  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: "#2f54eb",
          opacity: 1,
          border: 0,
          ...theme.applyStyles("dark", {
            backgroundColor: "#2ECA45",
          }),
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.grey[100],
        ...theme.applyStyles("dark", {
          color: theme.palette.grey[600],
        }),
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.7,
        ...theme.applyStyles("dark", {
          opacity: 0.3,
        }),
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 18,
      height: 18,
    },
    "& .MuiSwitch-track": {
      height: "86%",
      width: "90%",
      borderRadius: 26 / 2,
      backgroundColor: "#E9E9EA",
      border: "1px solid #dddddd",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
      ...theme.applyStyles("dark", {
        backgroundColor: "#39393D",
      }),
    },
  }));
  return (
    <Box>
      <Typography
        variant="body1"
        fontSize={32}
        fontWeight={600}
        color={"#263238"}
        gutterBottom
      >
        Informations de contact
      </Typography>
      <Typography variant="body1" fontSize={16} color={"#263238"} gutterBottom>
        Les personnes intéressées prendront contact avec vous en utilisant
        l'adresse e-mail associée à votre compte KI. Il est également recommandé
        d'inclure votre numéro de téléphone dans votre annonce.
      </Typography>
      <Box>
        <FormControl sx={{ mb: 0.5, mt: 3 }} variant="outlined" fullWidth>
          <Typography variant="body1" sx={{ fontSize: "12px" }}>
            Téléphone
            <span style={{ color: "#EC544B" }}>*</span>
          </Typography>
        </FormControl>
        <FormControl sx={{ mb: 1.7 }} variant="outlined" fullWidth>
          <Box
            display={"flex"}
            gap={{ xs: 6, sm: 1, md: 1 }}
            alignItems={"center"}
          >
            <PhoneInput
              className="mobile_input_field info_de_contact"
              country={selectedCountry?.countryCode}
              autoPlaceholder="off"
              enableSearch
              containerStyle={{ width: "32%", marginRight: "-16%" }}
              inputStyle={{
                width: "48%",
                height: "96%",
                background: "#FFFFFF",
              }}
              countryCodeEditable={false}
              separateDialCode={true}
              inputProps={{
                name: "telephone",
                required: true,
                readOnly: true,
              }}
              value={String(formData?.phone_code) || selectedCountry.dialCode}
              onChange={handlePhoneChange}
            />
            <TextField
              ref={setRef("phone_number")}
              required
              type="tel"
              id="Phone"
              smallsize="small"
              placeholder="6 12 34 56 78"
              name="phone_number"
              value={formData?.phone_number || ""}
              onChange={handlePhoneNumberChange}
              inputProps={{
                maxLength: 10,
                inputMode: "numeric",
                pattern: "[0-9]*",
              }}
              sx={{
                "& .MuiOutlinedInput-root": {
                  height: 45,
                  borderRadius: "22.5px",
                  background: "#FFFFFF",
                  "& fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&:hover fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: "rgba(132, 159, 186, 0.45)",
                  },
                },
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <span
              style={{
                marginRight: "16px",
                display: "flex",
                justifyContent: "space-around",
                alignItems: " center",
              }}
            >
              <PhoneDisabledIcon
                sx={{
                  background: "#fff",
                  borderRadius: "50%",
                  mx: 1,
                  width: "38px",
                  height: "38px",
                  padding: "9px",
                }}
              />{" "}
              Masquer le numéro dans l'annonce
            </span>
            <FormControlLabel
              control={
                <IOSSwitch
                  sx={{ margin: "auto 10px" }}
                  defaultChecked
                  checked={checked}
                  onChange={handleChange}
                />
              }
            />
          </Box>
        </FormControl>
      </Box>
    </Box>
  );
}
