import React, { useState, useCallback, useRef, useEffect } from "react";
import { InputBase } from "@mui/material";
import debounce from "lodash.debounce";

const MAPBOX_TOKEN = process.env.REACT_APP_MAPBOX_API_KEY;

const AutoComplete = ({ address, setAddress, handleSelect }) => {
  const [query, setQuery] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const inputRef = useRef(null);
  const suggestionBoxRef = useRef(null);

  const fetchSuggestions = async (query) => {
    if (!query) return;
    const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
      query
    )}.json?access_token=${MAPBOX_TOKEN}&autocomplete=true&country=ma&language=fr&types=poi,poi.landmark,place,region,postcode,locality`;
    try {
      const response = await fetch(url);
      const data = await response.json();
      setSuggestions(data.features || []);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  const debouncedFetchSuggestions = useCallback(
    debounce(fetchSuggestions, 300),
    []
  );

  useEffect(() => {
    if (address !== query) {
      setQuery(address);
    }
  }, [address, query]);

  const handleInputChange = (e) => {
    setQuery(e.target.value);
    setAddress(e.target.value);
    if (e.target.value) {
      debouncedFetchSuggestions(e.target.value);
    } else {
      setSuggestions([]);
    }
  };

  const handleSelectSuggestion = (suggestion) => {
    handleSelect(suggestion);
    setQuery(suggestion.place_name);
    setSuggestions([]);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        inputRef.current &&
        suggestionBoxRef.current &&
        !inputRef.current.contains(event.target) &&
        !suggestionBoxRef.current.contains(event.target)
      ) {
        setSuggestions([]);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, suggestionBoxRef]);

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <InputBase
        sx={{
          width: "100%",
          flex: 1,
          fontSize: "16px",
          color: "#495057",
          "&::placeholder": {
            color: "#495057",
            opacity: 1,
          },
          "&.MuiInputBase-root:before": {
            borderBottom: "none",
          },
          "&.MuiInputBase-root:hover:not(.Mui-disabled):before": {
            borderBottom: "none",
          },
          "&.MuiInputBase-root:after": {
            borderBottom: "none",
          },
          "&.MuiInputBase-root > input": {
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            width: "90%",
          },
        }}
        placeholder="Ville, arrondissement, quartier, code postal"
        inputRef={inputRef}
        value={query}
        onChange={handleInputChange}
        onFocus={() => {
          if (query) debouncedFetchSuggestions(query);
        }}
      />
      {suggestions.length > 0 && (
        <div
          ref={suggestionBoxRef}
          style={{
            position: "absolute",
            top: "100%",
            marginTop: "18px",
            borderRadius: "4px",
            textAlign: "left",
            left: 0,
            right: 0,
            backgroundColor: "#fff",
            border: "1px solid #ddd",
            zIndex: 1,
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              style={{
                padding: "10px",
                cursor: "pointer",
                borderBottom: "1px solid #eee",
                fontSize: "14px",
              }}
              onClick={() => handleSelectSuggestion(suggestion)}
            >
              {suggestion.place_name}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AutoComplete;
