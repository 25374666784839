import {
  Avatar,
  Popover,
  Box,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  Alert,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { BiUserCircle } from "react-icons/bi";
import { AiOutlineLogout } from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { BsQuestionCircle } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { loginUser, selectUser } from "../Redux/reducer/reducer";
import { deleteRequest, getApiRequest, putRequest } from "../apiHandler";
import { toast } from "react-toastify";
import { UserProfile } from "./ConstantImagesURL";
import { Badge } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { io } from "socket.io-client";
const socket = io(process.env.REACT_APP_API_URL);

export default function UserAction() {
  const user = useSelector(selectUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const logout = async () => {
    dispatch(loginUser(null));
    localStorage.removeItem("token");
    const logoutRes = await deleteRequest(
      `/api/logout-users-activity/${user.token}`
    );
    if (logoutRes?.status === 200) {
      window.location.replace(
        `${process.env.REACT_APP_WEB_URL}/sign-in?origin=true`
      );
    } else {
      toast.error(logoutRes?.message);
    }
  };
  const [anchorElNotify, setAnchorElNotify] = useState(null);
  const [notifications, setNotifications] = useState([]);

  const open = Boolean(anchorElNotify);
  const handleIconClick = (event) => {
    setAnchorElNotify(event.currentTarget);
  };
  const handleCloseNotify = (id) => {
    setAnchorElNotify(null);
    id && readNotification(id);
  };

  const getAllNotifications = async () => {
    const res = await getApiRequest("/api/get-all-notifications");
    if (res?.status === 200) {
      setNotifications(res?.response);
    } else {
      // console.log(res?.message);
    }
  };
  const readNotification = async (notifyId) => {
    const res = await putRequest(`/api/read-notification/${notifyId}`, {});
    if (res?.status === 200) {
      getAllNotifications();
    } else {
      // console.log(res?.message);
    }
  };
  useEffect(() => {
    getAllNotifications();
  }, []);

  // socket for new notification
  useEffect(() => {
    if (user._id) {
      socket.emit("join_notification_room", user._id);

      // Listen for new notifications
      socket.on("new_notification", (notification) => {
        // console.log("Received notification on frontend:", notification);
        setNotifications((prevNotifications) => [
          ...prevNotifications,
          notification.data,
        ]);
      });

      // Handle reconnection and re-join the room
      socket.on("reconnect", () => {
        socket.emit("join_notification_room", user._id);
      });

      // Clean up listeners when the component unmounts
      return () => {
        socket.off("new_notification");
        socket.off("reconnect");
      };
    }
  }, [user]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "1.5rem",
        }}
      >
        <div>
          <IconButton
            id="basic-button"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            color="primary"
            onClick={handleIconClick}
          >
            <Badge badgeContent={notifications.length} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>

          <Menu
            id="basic-menu"
            anchorEl={anchorElNotify}
            onClick={() => handleCloseNotify(null)}
            open={open}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            sx={{
              ".MuiPaper-root ul": {
                width: "400px",
                maxHeight: "400px",
                overflow: "auto",
              },
            }}
            slotProps={{
              paper: {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&::before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              },
            }}
            transformOrigin={{ horizontal: "right", vertical: "top" }}
            anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
          >
            {notifications.length > 0 ? (
              notifications.map((notification) => {
                const usersData = JSON.parse(notification.data);
                return (
                  <MenuItem onClick={() => handleCloseNotify(notification._id)}>
                    <Alert
                      sx={{ width: "100%", whiteSpace: "wrap" }}
                      severity="info"
                    >
                      {notification.message}
                      contactez nous avec {usersData.name} ({usersData.email}),
                      mobile : {usersData.telephone} concernant cette annonce :
                      <a
                        target="_blank"
                        href={`${process.env.REACT_APP_WEB_URL}/annonce-detail/${usersData.annonce_id}`}
                      >
                        cliquez ici
                      </a>
                    </Alert>
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem onClick={() => handleCloseNotify(null)}>
                <Alert sx={{ width: "100%" }} severity="danger">
                  Aucune nouvelle notification
                </Alert>
              </MenuItem>
            )}
          </Menu>
        </div>
        <Tooltip
          title="Aide"
          sx={{ cursor: "pointer", display: { xs: "none", md: "flex" } }}
        >
          <Stack
            direction="row"
            spacing={0.7}
            justifyContent="space-between"
            alignItems="center"
          >
            <BsQuestionCircle
              style={{ color: "#2f54eb", fontSize: "1.5rem" }}
            />
            <Typography variant="subtitle1" component={Box}>
              Aide
            </Typography>
          </Stack>
        </Tooltip>
        <Box
          sx={{
            display: {
              xs: "flex",
              lg: "flex",
            },
            alignItems: "center",
            gap: "0px",
          }}
        >
          <Tooltip title="Account settings">
            <IconButton
              component={Box}
              size="large"
              onClick={handleMenu}
              color="#0066ee"
              disableRipple
              sx={{
                p: 0,
              }}
            >
              <Avatar
                src={`${UserProfile}${user?.profile_image}`}
                alt="User Profile"
                sx={{
                  width: 30,
                  height: 30,
                  aspectRatio: "1",
                  borderRadius: "50%",
                }}
              >
                {user?.firstname?.charAt(0).toUpperCase() ?? "U"}
              </Avatar>
              &nbsp;
              <Box textAlign="left">
                <Typography variant="subtitle2" component={Box}>
                  {user && "Bonjour ,"}
                </Typography>
                <Typography
                  variant="subtitle1"
                  component={Box}
                  sx={{
                    fontWeight: "500",
                    lineHeight: "1.2",
                    textTransform: "capitalize",
                  }}
                >
                  {user &&
                    (user?.firstname &&
                      `${user?.firstname
                        ?.charAt(0)
                        .toUpperCase()}${user?.firstname?.slice(1)}`) +
                      (user?.lastname
                        ? ` ${user?.lastname
                            ?.charAt(0)
                            .toUpperCase()}${user?.lastname?.slice(1)}`
                        : "")}
                </Typography>
              </Box>
            </IconButton>
          </Tooltip>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: "visible",
                filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                mt: 1.5,
                "& .MuiAvatar-root": {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                "&:before": {
                  content: '""',
                  display: "block",
                  position: "absolute",
                  top: 0,
                  left: 14,
                  width: 10,
                  height: 10,
                  bgcolor: "background.paper",
                  transform: "translateY(-50%) rotate(45deg)",
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: "left", vertical: "top" }}
            anchorOrigin={{ horizontal: "left", vertical: "bottom" }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem
              component={Link}
              to="/user/mon-compte"
              sx={{ color: "#212529" }}
            >
              {" "}
              <BiUserCircle
                style={{ fontSize: "1.5rem", marginRight: "0.7rem" }}
              />{" "}
              Mon compte
            </MenuItem>
            <MenuItem sx={{ color: "#FE0F0F" }} onClick={() => logout()}>
              <AiOutlineLogout
                style={{ fontSize: "1.4rem", marginRight: "0.7rem" }}
              />
              Déconnexion
            </MenuItem>
          </Menu>
        </Box>
      </Box>
    </>
  );
}
