import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  Paper,
  Radio,
  RadioGroup,
  TextField,
  Avatar,
  Typography,
} from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { postApiRequest } from "../../apiHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { loginUser, selectUser } from "../../Redux/reducer/reducer";
import { useDispatch, useSelector } from "react-redux";
import { UserProfile } from "../../component/ConstantImagesURL";

const schema = yup
  .object()
  .shape({
    firstname: yup.string().required("Le prénom est requis"),
    lastname: yup.string().required("Le nom de famille est obligatoire"),
    telephone: yup
      .string()
      .required("Le numéro de téléphone est requis")
      .matches(
        /^\d{10}$/,
        "Le numéro de téléphone doit contenir exactement 10 chiffres"
      )
      .test(
        "is-numeric",
        "Le nombre doit être non négatif et contenir uniquement des chiffres",
        (value) => !isNaN(value) && Number(value) >= 0
      ),
  })
  .required();

function ProfileUpdate() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [civilite, setCivilite] = useState();
  const userData = useSelector(selectUser);
  const [avatarSrc, setAvatarSrc] = useState(null);
  const [avatarFile, setAvatarFile] = useState(null);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: "",
      lastname: "",
      telephone: "",
    },
  });
  useEffect(() => {
    if (userData?._id) {
      setValue("firstname", userData.firstname);
      setValue("lastname", userData.lastname);
      setValue("telephone", userData.telephone);
      setCivilite({ prefix: userData.prefix });
      if (userData.profile_image) {
        setAvatarSrc(UserProfile + userData.profile_image);
      }
    }
  }, [userData, setValue]);

  const profileOnSubmit = async (value) => {
    const formData = new FormData();
    formData.append("firstname", value.firstname);
    formData.append("lastname", value.lastname);
    formData.append("telephone", value.telephone);
    formData.append("prefix", civilite?.prefix);
    if (avatarFile) {
      formData.append("profile_image", avatarFile);
    }
    try {
      const profileRes = await postApiRequest(
        "/api/update-users-profile",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      if (profileRes?.status === 200) {
        toast.success(profileRes?.message);
        dispatch(loginUser(profileRes?.response));
        setTimeout(() => {
          navigate("/user/dashboard");
        }, 1000);
      } else {
        toast.error(profileRes?.message);
      }
    } catch (error) {
      toast.error("An error occurred while updating the profile.");
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file);
      setAvatarSrc(imageUrl);
      setAvatarFile(file);
      event.target.value = null;
    }
  };

  return (
    <div>
      <Paper
        elevation={3}
        sx={{
          p: { xs: 3, sm: 3 },
          width: "100%",
          borderRadius: "13px",
          position: "relative",
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Typography
            variant="body1"
            sx={{ color: "#00156A", fontWeight: "700" }}
          >
            Mes informations
          </Typography>
          <label
            style={{ cursor: "pointer" }}
            onClick={(e) => e.stopPropagation()}
          >
            <Avatar
              src={avatarSrc || `${UserProfile}${userData?.profile_image}`}
              alt="User Profile"
              sx={{
                width: 80,
                height: 80,
                aspectRatio: "1",
                borderRadius: "50%",
                fontSize: "48px",
              }}
            >
              {userData?.firstname?.charAt(0).toUpperCase() ?? "U"}
            </Avatar>
            <input
              id="file-input"
              type="file"
              accept="image/*"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
          </label>
        </Box>
        <Box component="form" onSubmit={handleSubmit(profileOnSubmit)}>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Civilité
            </Typography>
            <RadioGroup
              row
              aria-labelledby="Civilite"
              name="Civilite"
              defaultValue={userData?.prefix}
              onChange={(e) => setCivilite({ prefix: e.target.value })}
            >
              <FormControlLabel value="M" control={<Radio />} label="M." />
              <FormControlLabel value="Mme" control={<Radio />} label="Mme" />
            </RadioGroup>
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Nom
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="HERVIER"
              size="small"
              {...register("firstname")}
              error={!!errors?.firstname}
              helperText={errors?.firstname?.message}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Prénom
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="MAUD"
              size="small"
              {...register("lastname")}
              error={!!errors?.lastname}
              helperText={errors?.lastname?.message}
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              E-mail
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              size="small"
              value={userData?.email}
              disabled
            />
          </FormControl>
          <FormControl sx={{ width: "100%" }}>
            <Typography
              variant="body1"
              sx={{ mt: 2.5, mb: 0.7, fontWeight: "500", fontSize: "0.9rem" }}
            >
              Téléphone
            </Typography>
            <TextField
              fullWidth
              variant="outlined"
              placeholder="0685471254"
              size="small"
              {...register("telephone")}
              error={!!errors?.telephone}
              helperText={errors?.telephone?.message}
            />
          </FormControl>
          <Box sx={{ textAlign: "right", mt: 3 }}>
            <Button
              variant="contained"
              sx={{ width: { xs: "100%", sm: "auto" } }}
              type="submit"
            >
              Mettre à jour
            </Button>
          </Box>
        </Box>
        {/* <SupprimerMonCompte /> */}
      </Paper>
    </div>
  );
}

export default ProfileUpdate;
