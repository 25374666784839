import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  InputAdornment,
  Paper,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { toast } from "react-toastify";
import PublishCard from "./PublishCard";
import { IoSearch } from "react-icons/io5";
import { TbCurrentLocation } from "react-icons/tb";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { MapMarker } from "../../../component/ConstantImagesURL";
import axios from "axios";
import AutoComplete from "./AutoComplete";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_API_KEY;

export default function StepOne({ formData, setFormData, handleClick }) {
  const theme = useTheme();
  const isMobileScreen = useMediaQuery(theme.breakpoints.up("900"));
  const mapRefContainer = useRef(null);
  const markerRef = useRef(null);
  const [map, setMap] = useState(null);
  const [center, setCenter] = useState([-6.27614, 31.7917]);
  const [zoomLevel, setZoomLevel] = useState(5);
  const [address, setAddress] = useState(formData?.address || "");
  const [hasFlown, setHasFlown] = useState(false);
  const [mapStyle, setMapStyle] = useState(true);

  // Fixed bounds for Morocco
  const moroccoBounds = [
    [-17.2551456, 21.3365321], // Southwest coordinates (left bottom corner of Morocco)
    [-0.998429, 36.0505269], // Northeast coordinates (right top corner of Morocco)
  ];

  useEffect(() => {
    if (formData?.address) {
      setAddress(formData.address);
      if (formData?.lat && formData?.long) {
        const newCenter = { lat: formData.latitude, lng: formData.longitude };
        setCenter(newCenter);
        if (map && !hasFlown) {
          map.flyTo({
            center: [formData.longitude, formData.latitude],
            zoom: formData.street_check ? 17 : 18,
          });
          setZoomLevel(18);
          setHasFlown(true);
          if (markerRef.current) markerRef.current.remove();
          const newMarker = createCustomMarker([
            formData.longitude,
            formData.latitude,
          ]);
          newMarker.addTo(map);
          markerRef.current = newMarker;
        }
      }
    }
  }, [formData, map, hasFlown]);

  useEffect(() => {
    if (mapRefContainer.current && !map) {
      const initialCenter =
        formData.latitude && formData.longitude
          ? [formData.longitude, formData.latitude]
          : center;
      const initialZoom =
        formData.latitude && formData.longitude ? 18 : zoomLevel;
      const initializeMap = new mapboxgl.Map({
        container: mapRefContainer.current,
        style: "mapbox://styles/kifwatimmo/clqfcb8ek00h001o9a8dcgno3",
        center: initialCenter,
        zoom: initialZoom,
        locale: "fr",
        maxBounds: moroccoBounds,
        maxZoom: 18,
        minZoom: 5,
      });
      initializeMap.addControl(
        new mapboxgl.NavigationControl(),
        "bottom-right"
      );
      if (formData.latitude && formData.longitude) {
        const newMarker = createCustomMarker([
          formData.longitude,
          formData.latitude,
        ]);
        newMarker.addTo(initializeMap);
        markerRef.current = newMarker;
      }
      initializeMap.on("click", (e) => {
        const { lng, lat } = e.lngLat;
        handleMapClick(lng, lat, initializeMap);
      });
      initializeMap.on("moveend", () => {
        const { lng, lat } = initializeMap.getCenter();
        if (markerRef.current) markerRef.current.remove();
        const newMarker = createCustomMarker([lng, lat]);
        newMarker.addTo(initializeMap);
        markerRef.current = newMarker;
        getAddressFromLatLng(lng, lat, true);
      });
      setMap(initializeMap);
    }
  }, [map, formData, center, zoomLevel]);

  const handleAddressSelect = (selectedLocation) => {
    const [lng, lat] = selectedLocation.center;
    if (isLocationInsideMoroccoBounds(lng, lat)) {
      updateMarkerAndAddress(
        lng,
        lat,
        selectedLocation?.place_name,
        selectedLocation,
        map
      );
    } else {
      toast.error(
        "Vous ne pouvez sélectionner que des adresses à l'intérieur du Maroc."
      );
    }
  };

  const handleMapClick = (lng, lat, mapInstance) => {
    if (isLocationInsideMoroccoBounds(lng, lat)) {
      updateMarkerAndAddress(lng, lat, null, null, mapInstance);
    } else {
      toast.error(
        "Vous ne pouvez sélectionner que des adresses à l'intérieur du Maroc."
      );
    }
  };

  const isLocationInsideMoroccoBounds = (lng, lat) => {
    return (
      lng >= moroccoBounds[0][0] &&
      lng <= moroccoBounds[1][0] &&
      lat >= moroccoBounds[0][1] &&
      lat <= moroccoBounds[1][1]
    );
  };

  const updateMarkerAndAddress = async (
    lng,
    lat,
    inputAddress,
    selectedLocation,
    mapInstance
  ) => {
    // Remove existing marker
    if (markerRef.current) markerRef.current.remove();

    // Fly to the new location
    mapInstance.flyTo({ center: [lng, lat], zoom: 18 });

    // Add new marker at the new location
    const newMarker = createCustomMarker([lng, lat]);
    newMarker.addTo(mapInstance || map);
    markerRef.current = newMarker;

    setZoomLevel(18);

    let resolvedAddress = inputAddress;
    let placeData = selectedLocation;

    // Fetch the address if it's not provided
    if (!inputAddress) {
      const fetchedData = await getAddressFromLatLng(lng, lat);
      resolvedAddress = fetchedData?.formattedAddress;
      placeData = fetchedData?.placeData;

      // If no address is found, remove the marker and exit early
      if (!resolvedAddress || !placeData) {
        if (markerRef.current) markerRef.current.remove();
        setAddress(""); // Do not set any address
        return;
      }
    }

    // Set the address if valid data is found
    setAddress(resolvedAddress);
    updateFormData(placeData, resolvedAddress, { lat, lng }, true);
  };

  const getAddressFromLatLng = async (lng, lat, check = false) => {
    try {
      const url = `https://api.mapbox.com/geocoding/v5/mapbox.places/${lng},${lat}.json?access_token=${mapboxgl.accessToken}&autocomplete=true&country=ma&language=fr&types=poi,poi.landmark,place,region,postcode,locality`;
      const response = await axios.get(url);
      if (response?.data?.features?.length > 0) {
        const placeName = response?.data?.features[0]?.place_name || "";
        if (check && placeName) {
          setAddress(placeName);
          updateFormData(
            response?.data?.features[0],
            placeName,
            { lat, lng },
            true
          );
        }
        return {
          formattedAddress: placeName,
          placeData: response?.data?.features[0],
        };
      }
      return { formattedAddress: null, placeData: null };
    } catch (error) {
      return { formattedAddress: null, placeData: null };
    }
  };

  const updateFormData = (place, formattedAddress, selectedLocation, check) => {
    // Create the form data object to update
    const updatedFormData = {
      address: formattedAddress,
      latitude: selectedLocation.lat,
      longitude: selectedLocation.lng,
      location: `${selectedLocation.lat},${selectedLocation.lng}`,
      geo_location: { lat: selectedLocation.lat, lon: selectedLocation.lng },
      street_check: check,
      region: null,
      region_code: null,
      country: "morocco",
      city: null,
      commune: null,
      province: null,
      quartier: null,
    };
    const regions = {
      oriental: "oriental",
      "casablanca-settat": "casablanca",
      "marrakesh-safi": "marrakech",
      "drâa-tafilalet": "draa",
      "fès-meknès": "fes",
      "rabat-salé-kénitra": "rabat",
      "béni mellal-khénifra": "beni",
      "souss-massa": "souss",
      "guelmim-oued": "guelmim",
      "laâyoune-sakia el hamra": "laayoune",
      "dakhla-oued ed-dahab": "dakhla",
      "tanger-tétouan-al": "tanger",
    };
    const cleanString = (str) => str.toLowerCase().replace(/[\s-]/g, "");
    if (place && place.context) {
      place.context.forEach((component) => {
        const componentName = component.text.toLowerCase();
        const componentType = component.id.split(".")[0];
        if (componentType === "place") {
          updatedFormData.city = componentName;
        } else if (componentType === "region") {
          let regionKey = Object.keys(regions).find(
            (key) => key.toLowerCase() === componentName
          );
          if (!regionKey) {
            regionKey = Object.keys(regions).find((key) =>
              cleanString(componentName).includes(cleanString(key))
            );
          }
          if (regionKey) {
            updatedFormData.region = regionKey;
            updatedFormData.region_code = regions[regionKey];
          }
        } else if (componentType === "locality") {
          updatedFormData.commune = componentName;
        } else if (
          componentType === "neighborhood" ||
          componentType === "sublocality"
        ) {
          updatedFormData.quartier = componentName;
        }
      });
    }
    if (!updatedFormData.region || !updatedFormData.region_code) {
      const placeNameLower = cleanString(place.place_name);
      Object.entries(regions).forEach(([regionKey, regionValue]) => {
        const cleanedRegionKey = cleanString(regionKey);
        const cleanedRegionValue = cleanString(regionValue);
        if (
          placeNameLower.includes(cleanedRegionKey) ||
          placeNameLower.includes(cleanedRegionValue)
        ) {
          updatedFormData.region = regionKey;
          updatedFormData.region_code = regionValue;
        }
      });
    }
    setFormData((prevFormData) => ({
      ...prevFormData,
      ...updatedFormData,
    }));
  };

  const createCustomMarkerElement = () => {
    const markerElement = document.createElement("div");
    markerElement.style.position = "relative";
    markerElement.style.width = "90px";
    markerElement.style.height = "90px";
    const logoImage = document.createElement("img");
    logoImage.src = MapMarker;
    logoImage.style.position = "absolute";
    logoImage.style.top = "30%";
    logoImage.style.left = "52%";
    logoImage.style.transform = "translate(-50%, -50%)";
    logoImage.style.width = "100px";
    logoImage.style.height = "100px";
    const addressBox = document.createElement("div");
    addressBox.style.background = "white";
    addressBox.style.borderRadius = "4px";
    addressBox.style.padding = "8px";
    addressBox.style.boxShadow = "0 2px 10px rgba(0, 0, 0, 0.2)";
    addressBox.style.position = "absolute";
    addressBox.style.bottom = "106px";
    addressBox.style.left = "50%";
    addressBox.style.transform = "translateX(-50%)";
    addressBox.style.textAlign = "center";
    addressBox.style.fontSize = "14px";
    addressBox.style.width = "250px";
    addressBox.innerText =
      "Glissez et déposez la broche pour définir l'emplacement";
    markerElement.appendChild(logoImage);
    markerElement.appendChild(addressBox);
    return markerElement;
  };

  const createCustomMarker = (coordinates) => {
    const markerElement = createCustomMarkerElement();
    return new mapboxgl.Marker(markerElement).setLngLat(coordinates);
  };

  const handleNext = () => {
    if (!address) {
      toast.error("L'adresse est requise.");
      return;
    }
    handleClick("next");
  };

  const getUserLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          handleMapClick(longitude, latitude, map);
        },
        () => {
          toast.error(
            "Impossible d'obtenir votre position. Assurez-vous que votre GPS est allumé !"
          );
        }
      );
    } else {
      toast.info(
        "La géolocalisation n'est pas prise en charge par ce navigateur."
      );
    }
  };

  const changeStyle = () => {
    const currentStyle = map.getStyle();
    if (currentStyle.sprite.includes("clqfcb8ek00h001o9a8dcgno3")) {
      map.setStyle("mapbox://styles/kifwatimmo/clskhz5c800h201qxfnrz1y3u");
      setMapStyle(false);
    } else {
      setMapStyle(true);
      map.setStyle("mapbox://styles/kifwatimmo/clqfcb8ek00h001o9a8dcgno3");
    }
  };

  return (
    <>
      <Box
        sx={{
          mt: 6,
          mb: 12,
        }}
      >
        <Grid container spacing={8} justifyContent="center" alignItems="center">
          <Grid item xs={12} md={6}>
            <Box
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
            >
              <Typography
                variant="body1"
                fontSize={24}
                fontWeight={600}
                color={"#263238"}
                gutterBottom
              >
                Adresse du bien
              </Typography>
              <Typography variant="body1" fontSize={16} color={"#263238"}>
                Entrez l'adresse du bien dans la barre de recherche.
              </Typography>
              <Box>
                <Paper
                  elevation={0}
                  variant="outlined"
                  component="form"
                  sx={{
                    borderRadius: "200px",
                    mt: 6,
                    mx: "auto",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      position: "relative",
                      justifyContent: "space-between",
                      height: "67px",
                      width: "100%",
                    }}
                    id="DivBox"
                  >
                    <InputAdornment sx={{ ml: 1 }} position="start">
                      <LocationOnIcon sx={{ color: "#0066EE" }} />
                    </InputAdornment>
                    <AutoComplete
                      address={address}
                      setAddress={setAddress}
                      handleSelect={handleAddressSelect}
                    />
                    {!isMobileScreen ? (
                      <Box
                        onClick={() =>
                          !markerRef.current
                            ? (function () {
                                toast.error(
                                  "Vous devez sélectionner une rue ou une adresse."
                                );
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  behavior: "smooth",
                                });
                              })()
                            : handleNext()
                        }
                        sx={{
                          width: "fit-content",
                          background: "#0066EE",
                          borderRadius: "50%",
                          padding: "12px",
                          boxShadow: "0 8px 20px 0 rgba(0, 94, 220, 0.12)",
                          display: "flex",
                          mr: 1,
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <IoSearch color="#fff" size={20} />
                      </Box>
                    ) : (
                      <Button
                        className="common_btn"
                        variant="contained"
                        onClick={() =>
                          !markerRef.current
                            ? (function () {
                                toast.error(
                                  "Vous devez sélectionner une rue ou une adresse."
                                );
                                window.scrollTo({
                                  top: document.body.scrollHeight,
                                  behavior: "smooth",
                                });
                              })()
                            : handleNext()
                        }
                        sx={{
                          px: { sm: 5, md: 2 },
                          borderRadius: "100px",
                          background: "#0066EE",
                          boxShadow: "0 8px 20px 0 rgba(0, 94, 220, 0.12)",
                          fontSize: "16px !important",
                          fontWeight: "500 !important",
                          height: "56px",
                          width: "134px",
                          maxHeight: "none !important",
                          marginRight: "5px",
                          fontFamily: `"Inter", sans-serif`,
                        }}
                      >
                        Recherher
                      </Button>
                    )}
                  </Box>
                </Paper>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              background:
                "url(https://s3-alpha-sig.figma.com/img/fd78/f384/914dcb7f51645e2d4e1fb92a043ad86f?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lhaxnrPwnfegrKJoCsJV38B3PTwyuKTmfMsukC8wl4TCGDx~DYmebhqr4B8Tnt3X35u7r4aDM9vtxPxLOu0E8pNohqX997aOsQZAyYEx1Xqk0PDOPaeVhJFoCaPFsEgNikyC5jKdkD0cMiA6G1A1Dd3hABzalf7EKojWoa3jDzubXhcrEPfmITb7ttscCvS60DjqSVBmlPc3FDjudZlEBjtw65upcp1LlhWS3nWmsKjuQwcx222cbTNF1qSIzEeEeMwa1sgotprfp1DtoGmKaEdx00UyIJ5-wXG4QRwPvRPIYtMcFWqQsu~K~EX9FZPL5NDLzHYSw7xslBQJOjlm4A__) no-repeat center",
              backgroundRepeat: "round",
            }}
          >
            <PublishCard />
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{ paddingTop: "0 !important" }}
          >
            <Box width={"fit-content"} mt={6}>
              <Typography
                variant="body1"
                sx={{
                  my: 2,
                  cursor: "pointer",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: "#263238",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                  ":hover": {
                    color: "#0066EE",
                  },
                }}
                onClick={getUserLocation}
              >
                <TbCurrentLocation
                  size={20}
                  color="#0066EE"
                  style={{ marginRight: "8px" }}
                />
                Me géolocaliser
              </Typography>
            </Box>
            <Box>
              <Box
                ref={mapRefContainer}
                sx={{
                  height: "70vh",
                  width: "100%",
                  borderRaidus: "10px !important",
                }}
              />
              <Box sx={{ position: "relative", bottom: 140, left: 0 }}>
                <button
                  className="mapStyleChangeBtn estimation_map"
                  onClick={() => changeStyle()}
                >
                  {mapStyle ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-globe-central-south-asia"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0M4.882 1.731a.48.48 0 0 0 .14.291.487.487 0 0 1-.126.78l-.291.146a.7.7 0 0 0-.188.135l-.48.48a1 1 0 0 1-1.023.242l-.02-.007a1 1 0 0 0-.462-.04 7 7 0 0 1 2.45-2.027m-3 9.674.86-.216a1 1 0 0 0 .758-.97v-.184a1 1 0 0 1 .445-.832l.04-.026a1 1 0 0 0 .152-1.54L3.121 6.621a.414.414 0 0 1 .542-.624l1.09.818a.5.5 0 0 0 .523.047.5.5 0 0 1 .724.447v.455a.8.8 0 0 0 .131.433l.795 1.192a1 1 0 0 1 .116.238l.73 2.19a1 1 0 0 0 .949.683h.058a1 1 0 0 0 .949-.684l.73-2.189a1 1 0 0 1 .116-.238l.791-1.187A.45.45 0 0 1 11.743 8c.16 0 .306.084.392.218.557.875 1.63 2.282 2.365 2.282l.04-.001a7.003 7.003 0 0 1-12.658.905Z" />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="14"
                      height="14"
                      fill="currentColor"
                      className="bi bi-map"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M15.817.113A.5.5 0 0 1 16 .5v14a.5.5 0 0 1-.402.49l-5 1a.5.5 0 0 1-.196 0L5.5 15.01l-4.902.98A.5.5 0 0 1 0 15.5v-14a.5.5 0 0 1 .402-.49l5-1a.5.5 0 0 1 .196 0L10.5.99l4.902-.98a.5.5 0 0 1 .415.103M10 1.91l-4-.8v12.98l4 .8zm1 12.98 4-.8V1.11l-4 .8zm-6-.8V1.11l-4 .8v12.98z"
                      />
                    </svg>
                  )}
                </button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {/* Button */}
      <Box
        position="fixed"
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
          top: "auto",
          bottom: 0,
          background: "#FFFFFF",
          borderTop: "1px solid #F1F5FE",
          zIndex: 9,
        }}
        elevation={0}
      >
        <Box
          sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
        >
          <Box sx={{ flex: "1 1 auto" }} />
          <Button
            variant="outlined"
            disabled={!markerRef.current}
            sx={{
              minWidth: { xs: "100%", sm: "100%", md: "150px", lg: "150px" },
              minHeight: { xs: "56px", sm: "56px" },
              px: { xs: 3, sm: 5 },
              fontFamily: `"Inter",sans-serif`,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "28px",
              border: "none !important",
              background: !markerRef.current ? "#DAE3EB" : "#0066EE",
              color: !markerRef.current ? "#DAE3EB" : "#FFFFFF",
              boxShadow: "none",
              "&:hover": {
                background: "#0066EE",
                color: "#FFFFFF",
              },
            }}
            onClick={() =>
              !markerRef.current
                ? toast.error("Vous devez sélectionner une rue ou une adresse.")
                : handleNext()
            }
          >
            Suivant
          </Button>
        </Box>
      </Box>
      {/* Button */}
    </>
  );
}

// import React, { useEffect, useState } from "react";
// import {
//   Box,
//   Typography,
//   Button,
//   InputAdornment,
//   Paper,
//   useMediaQuery,
//   useTheme,
//   Grid,
// } from "@mui/material";
// import LocationOnIcon from "@mui/icons-material/LocationOn";
// import { toast } from "react-toastify";
// import PublishCard from "./PublishCard";
// import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
// import { IoSearch } from "react-icons/io5";
// import { TbCurrentLocation } from "react-icons/tb";

// export default function StepOne({ formData, setFormData, handleClick }) {
//   const theme = useTheme();
//   const isMobileScreen = useMediaQuery(theme.breakpoints.up("900"));
//   const [address, setAddress] = useState(formData?.address || "");

//   const [map, setMap] = useState(null);
//   const [autocomplete, setAutocomplete] = useState(null);
//   const [marker, setMarker] = useState(null);
//   const [center, setCenter] = useState({ lat: 31.7917, lng: -7.0926 });

//   const { isLoaded } = useJsApiLoader({
//     id: "google-map-script",
//     version: "weekly",
//     googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
//     libraries: ["places"],
//     language: "fr",
//   });

//   useEffect(() => {
//     if (formData?.latitude && formData?.longitude && formData?.street_check) {
//       setMarker({ lat: formData.latitude, lng: formData.longitude });
//       setCenter({ lat: formData.latitude, lng: formData.longitude });
//     } else if (
//       formData?.latitude &&
//       formData?.longitude &&
//       formData.street_check === undefined
//     ) {
//       setMarker({ lat: formData.latitude, lng: formData.longitude });
//       setCenter({ lat: formData.latitude, lng: formData.longitude });
//     } else {
//       setMarker(null);
//     }
//   }, [formData?.street_check]);

//   useEffect(() => {
//     if (map && Object.keys(formData).length > 0) {
//       setCenter({ lat: formData?.latitude, lng: formData?.longitude });
//       map.panTo({ lat: formData?.latitude, lng: formData?.longitude });
//       setTimeout(() => map.setZoom(16));
//     }
//     if (isLoaded) {
//       const autocompleteInstance = new window.google.maps.places.Autocomplete(
//         document.getElementById("autocomplete"),
//         {
//           componentRestrictions: { country: "ma" },
//         }
//       );
//       setAutocomplete(autocompleteInstance);

//       autocompleteInstance.addListener("place_changed", () => {
//         const place = autocompleteInstance.getPlace();
//         if (place.geometry) {
//           const selectedLocation = {
//             lat: place.geometry.location.lat(),
//             lng: place.geometry.location.lng(),
//           };
//           setCenter(selectedLocation);
//           let check = false;
//           let formattedAddress = place.formatted_address;
//           const plusCodeComponent = place.address_components.find(
//             (component) =>
//               component.types.includes("street-address") ||
//               component.types.includes("street_number") ||
//               component.types.includes("plus_code")
//           );
//           const plusCode = plusCodeComponent ? plusCodeComponent.long_name : "";
//           if (plusCode && formattedAddress.includes(plusCode)) {
//             formattedAddress = formattedAddress
//               .replace(`${plusCode}, `, "")
//               .replace(plusCode, "");
//             check = true;
//             setMarker(selectedLocation);
//             setCenter(selectedLocation);
//             map.setZoom(17);
//           }
//           setAddress(formattedAddress);
//           if (map.panTo) {
//             map.panTo(selectedLocation);
//             map.setZoom(16);
//           }
//           updateFormData(place, formattedAddress, selectedLocation, check);
//         }
//       });
//     }
//   }, [isLoaded, map, setFormData]);

//   const moroccoBounds = {
//     north: 36.0505269, // max lat
//     south: 20.0, // Adjusted min lat value to include more southern cities
//     east: -0.998429, // max lng
//     west: -17.2551456, // min lng
//   };

//   const onMapClick = (e) => {
//     const lat = e.latLng.lat();
//     const lng = e.latLng.lng();
//     const selectedLocation = { lat, lng };
//     setMarker(selectedLocation);
//     setCenter(selectedLocation);
//     getAddressFromLatLng(lat, lng);
//     map.setZoom(17);
//   };

//   const getAddressFromLatLng = (lat, lng) => {
//     const geocoder = new window.google.maps.Geocoder();
//     geocoder.geocode({ location: { lat, lng } }, (results, status) => {
//       if (status === "OK" && results[0]) {
//         let formattedAddress = results[0].formatted_address;
//         const plusCodeComponent = results[0].address_components.find(
//           (component) =>
//             component.types.includes("street-address") ||
//             component.types.includes("street_number") ||
//             component.types.includes("plus_code")
//         );
//         const plusCode = plusCodeComponent ? plusCodeComponent.long_name : "";
//         if (plusCode && formattedAddress.includes(plusCode)) {
//           formattedAddress = formattedAddress
//             .replace(`${plusCode}, `, "")
//             .replace(plusCode, "");
//         }
//         setAddress(formattedAddress);
//         updateFormData(results[0], formattedAddress, { lat, lng }, true);
//       }
//     });
//   };

//   const updateFormData = (place, formattedAddress, selectedLocation, check) => {
//     const updatedFormData = {
//       address: formattedAddress,
//       latitude: selectedLocation.lat,
//       longitude: selectedLocation.lng,
//       location: `${selectedLocation.lat},${selectedLocation.lng}`,
//       geo_location: { lat: selectedLocation.lat, lon: selectedLocation.lng },
//       street_check: check,
//       region: null,
//       region_code: null,
//       country: "morocco",
//       city: null,
//       commune: null,
//       province: null,
//       quartier: null,
//     };
//     const regions = {
//       oriental: "oriental",
//       "casablanca-settat": "casablanca",
//       "marrakesh-safi": "marrakech",
//       "drâa-tafilalet": "draa",
//       "fès-meknès": "fes",
//       "rabat-salé-kénitra": "rabat",
//       "béni mellal-khénifra": "beni",
//       "souss-massa": "souss",
//       "guelmim-oued": "guelmim",
//       "laâyoune-sakia el hamra": "laayoune",
//       "dakhla-oued ed-dahab": "dakhla",
//       "tanger-tétouan-al": "tanger",
//     };
//     place.address_components.forEach((component) => {
//       const componentName = component.long_name.toLowerCase();
//       const typeMap = {
//         locality: "city",
//         administrative_area_level_2: "commune",
//         administrative_area_level_1: "province",
//         sublocality: "quartier",
//       };
//       const mappedType = typeMap[component.types[0]];
//       if (mappedType === "city")
//         updatedFormData[
//           mappedType
//         ] = updatedFormData.city = updatedFormData.commune = componentName;
//     });
//     let addressString = place.address_components
//       .map((item) => item.long_name.toLowerCase())
//       .join(" ");
//     Object.entries(regions).forEach(([regionKey, regionValue]) => {
//       const cleanedRegionKey = regionKey.toLowerCase().replace(/[\s-]/g, "");
//       const cleanedAddressString = addressString.replace(/[\s-]/g, "");
//       if (
//         cleanedAddressString.includes(cleanedRegionKey) ||
//         cleanedAddressString.includes(regionValue)
//       ) {
//         updatedFormData.region = regionKey;
//         updatedFormData.region_code = regionValue;
//         // updatedFormData.province = regionValue;
//       }
//     });
//     console.log("updatedFormData", updatedFormData);
//     setFormData((prevFormData) => ({ ...prevFormData, ...updatedFormData }));
//   };

//   const getUserLocation = () => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const { latitude, longitude } = position.coords;
//           const currentLocation = { lat: latitude, lng: longitude };
//           setCenter(currentLocation);
//           setMarker(currentLocation);
//           getAddressFromLatLng(latitude, longitude);
//           map.setZoom(17);
//         },
//         (error) => {
//           console.error("Error getting user location:", error);
//         }
//       );
//     } else {
//       console.error("Geolocation is not supported by this browser.");
//     }
//   };

//   const handleNext = () => {
//     if (!address) {
//       toast.error("L'adresse est requise");
//       return;
//     }
//     handleClick("next");
//   };
//   return (
//     <>
//       <Box
//         sx={{
//           mt: 6,
//           mb: 12,
//         }}
//       >
//         <Grid container spacing={8} justifyContent="center" alignItems="center">
//           <Grid item xs={12} md={6}>
//             <Box
//               display={"flex"}
//               flexDirection={"column"}
//               justifyContent={"center"}
//             >
//               <Typography
//                 variant="body1"
//                 fontSize={24}
//                 fontWeight={600}
//                 color={"#263238"}
//                 gutterBottom
//               >
//                 Adresse du bien
//               </Typography>
//               <Typography variant="body1" fontSize={16} color={"#263238"}>
//                 Entrez l'adresse du bien dans la barre de recherche.
//               </Typography>
//               <Box>
//                 <Paper
//                   elevation={0}
//                   variant="outlined"
//                   component="form"
//                   sx={{
//                     borderRadius: "200px",
//                     mt: 6,
//                     mx: "auto",
//                     alignItems: "center",
//                     width: "100%",
//                   }}
//                 >
//                   <Box
//                     sx={{
//                       display: "flex",
//                       alignItems: "center",
//                       position: "relative",
//                       justifyContent: "space-between",
//                       height: "67px",
//                       width: "100%",
//                     }}
//                     id="DivBox"
//                   >
//                     <InputAdornment sx={{ ml: 1 }} position="start">
//                       <LocationOnIcon sx={{ color: "#0066EE" }} />
//                     </InputAdornment>
//                     <input
//                       id="autocomplete"
//                       type="text"
//                       placeholder="Adresse du bien"
//                       value={address}
//                       onChange={(e) => setAddress(e.target.value)}
//                       style={{
//                         width: "100%",
//                         padding: "20px 0",
//                         border: "none",
//                         borderRadius: "6px",
//                         fontSize: "16px",
//                         outline: "none",
//                         ":hover": {
//                           border: "none",
//                         },
//                         ":focus": {
//                           border: "none",
//                         },
//                       }}
//                     />
//                     {!isMobileScreen ? (
//                       <Box
//                         onClick={() =>
//                           marker === null
//                             ? (function () {
//                                 toast.error(
//                                   "Vous devez sélectionner une rue ou une adresse."
//                                 );
//                                 window.scrollTo({
//                                   top: document.body.scrollHeight,
//                                   behavior: "smooth",
//                                 });
//                               })()
//                             : handleNext()
//                         }
//                         sx={{
//                           width: "fit-content",
//                           background: "#0066EE",
//                           borderRadius: "50%",
//                           padding: "12px",
//                           boxShadow: "0 8px 20px 0 rgba(0, 94, 220, 0.12)",
//                           display: "flex",
//                           mr: 1,
//                           alignItems: "center",
//                           justifyContent: "center",
//                         }}
//                       >
//                         <IoSearch color="#fff" size={20} />
//                       </Box>
//                     ) : (
//                       <Button
//                         className="common_btn"
//                         variant="contained"
//                         onClick={() =>
//                           marker === null
//                             ? (function () {
//                                 toast.error(
//                                   "Vous devez sélectionner une rue ou une adresse."
//                                 );
//                                 window.scrollTo({
//                                   top: document.body.scrollHeight,
//                                   behavior: "smooth",
//                                 });
//                               })()
//                             : handleNext()
//                         }
//                         sx={{
//                           px: { sm: 5, md: 2 },
//                           borderRadius: "100px",
//                           background: "#0066EE",
//                           boxShadow: "0 8px 20px 0 rgba(0, 94, 220, 0.12)",
//                           fontSize: "16px !important",
//                           fontWeight: "500 !important",
//                           height: "56px",
//                           width: "134px",
//                           maxHeight: "none !important",
//                           marginRight: "5px",
//                           fontFamily: `"Inter", sans-serif`,
//                         }}
//                       >
//                         Recherher
//                       </Button>
//                     )}
//                   </Box>
//                 </Paper>
//               </Box>
//             </Box>
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             md={6}
//             style={{
//               background:
//                 "url(https://s3-alpha-sig.figma.com/img/fd78/f384/914dcb7f51645e2d4e1fb92a043ad86f?Expires=1713744000&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4&Signature=lhaxnrPwnfegrKJoCsJV38B3PTwyuKTmfMsukC8wl4TCGDx~DYmebhqr4B8Tnt3X35u7r4aDM9vtxPxLOu0E8pNohqX997aOsQZAyYEx1Xqk0PDOPaeVhJFoCaPFsEgNikyC5jKdkD0cMiA6G1A1Dd3hABzalf7EKojWoa3jDzubXhcrEPfmITb7ttscCvS60DjqSVBmlPc3FDjudZlEBjtw65upcp1LlhWS3nWmsKjuQwcx222cbTNF1qSIzEeEeMwa1sgotprfp1DtoGmKaEdx00UyIJ5-wXG4QRwPvRPIYtMcFWqQsu~K~EX9FZPL5NDLzHYSw7xslBQJOjlm4A__) no-repeat center",
//               backgroundRepeat: "round",
//             }}
//           >
//             <PublishCard />
//           </Grid>
//           <Grid
//             item
//             xs={12}
//             sm={12}
//             md={12}
//             lg={12}
//             sx={{ paddingTop: "0 !important" }}
//           >
//             <Box width={"fit-content"} mt={6}>
//               <Typography
//                 variant="body1"
//                 sx={{
//                   my: 2,
//                   cursor: "pointer",
//                   fontSize: "16px",
//                   fontWeight: 500,
//                   color: "#263238",
//                   display: "flex",
//                   alignItems: "center",
//                   justifyContent: "left",
//                   ":hover": {
//                     color: "#0066EE",
//                   },
//                 }}
//                 onClick={getUserLocation}
//               >
//                 <TbCurrentLocation
//                   size={20}
//                   color="#0066EE"
//                   style={{ marginRight: "8px" }}
//                 />
//                 Me géolocaliser
//               </Typography>
//             </Box>
//             {isLoaded && (
//               <GoogleMap
//                 mapContainerStyle={{
//                   width: "100%",
//                   height: "72vh",
//                   borderRadius: "16px",
//                   marginTop: "30px",
//                 }}
//                 center={center}
//                 zoom={5}
//                 onClick={onMapClick}
//                 onLoad={(map) => {
//                   setMap(map);
//                 }}
//                 options={{
//                   gestureHandling: "greedy",
//                   minZoom: 6,
//                   maxZoom: 21,
//                   restriction: {
//                     latLngBounds: moroccoBounds,
//                     strictBounds: false, // Allow panning outside the bounds (optional)
//                   },
//                   styles: [
//                     {
//                       featureType: "administrative",
//                       elementType: "geometry",
//                       stylers: [
//                         {
//                           visibility: "off", // Hide administrative boundaries
//                         },
//                       ],
//                     },
//                     {
//                       featureType: "administrative.country",
//                       elementType: "labels",
//                       stylers: [
//                         { visibility: "off" }, // Hide all country labels
//                       ],
//                     },
//                   ],
//                 }}
//               >
//                 {marker && <Marker position={marker} />}
//               </GoogleMap>
//             )}
//           </Grid>
//         </Grid>
//       </Box>
//       {/* Button */}
//       <Box
//         position="fixed"
//         sx={{
//           width: { xs: "100%", sm: "100%", md: "100%", lg: "83%" },
//           top: "auto",
//           bottom: 0,
//           background: "#FFFFFF",
//           borderTop: "1px solid #F1F5FE",
//         }}
//         elevation={0}
//       >
//         <Box
//           sx={{ display: "flex", flexDirection: "row", padding: "10px 50px" }}
//         >
//           <Box sx={{ flex: "1 1 auto" }} />
//           <Button
//             variant="outlined"
//             disabled={marker === null ? true : false}
//             sx={{
//               minWidth: { xs: "100%", sm: "100%", md: "150px", lg: "150px" },
//               minHeight: { xs: "56px", sm: "56px" },
//               px: { xs: 3, sm: 5 },
//               fontFamily: `"Inter",sans-serif`,
//               fontSize: 16,
//               fontWeight: 500,
//               borderRadius: "28px",
//               border: "none !important",
//               background: marker === null ? "#DAE3EB" : "#0066EE",
//               color: marker === null ? "#DAE3EB" : "#FFFFFF",
//               boxShadow: "none",
//               "&:hover": {
//                 background: "#0066EE",
//                 color: "#FFFFFF",
//               },
//             }}
//             onClick={() =>
//               marker === null
//                 ? toast.error("Vous devez sélectionner une rue ou une adresse.")
//                 : handleNext()
//             }
//           >
//             Suivant
//           </Button>
//         </Box>
//       </Box>
//       {/* Button */}
//     </>
//   );
// }
